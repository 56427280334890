@media (max-width: 576px) {
  .dropdown .header-company-dropdown {
    padding: 0 !important;
    margin: 0;
    width: 100%;
    border: none;
  }
}
@media (min-width: 991px) {
.recruiter-company-dropdown{
  padding-top: 20px;
  margin-right: 18px;
}
.dropdown .header-company-dropdown {
  width: 420px;
}
}
@media (max-width: 991px) {
  .recruiter-company-list{
    border-radius: 6px !important;
  }
  .dropdown .header-company-dropdown {
    margin-bottom: 0px;
  }


  }