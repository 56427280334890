.col-5 .icon-big img {
  width: 40px;
  height: 40px;
}
.card-stats .card-body .numbers {
  text-align: center;
  padding-top: 25px;
}
.card-stats .card-body .numbers .card-title {
  font-size: 38px !important;
}
.card.card-stats:hover .numbers .card-title {
  color: #f7931e;
}
.header-dashboard-row {
  font-size: 12px;
  color: #66615b;
  padding-left: 15px;
  font-weight: 400;
}

.content a .card {
  border-radius: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .breadcrumb-revised {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 1199px) and (min-width: 991px) {
  .card-stats .card-body .numbers .card-title {
    font-size: 28px !important;
  }
}
.dropdown-menu {
  min-width: 8rem;
}
.dropdown-menu .dropdown-item {
  padding: 5px 16px;
}
.candidate-dashboard .dropdown-menu .dropdown-item i[class*='fa'] {
  font-size: 15px;
}
.candidate-dashboard .position-save-icons {
  position: relative;
  top: 4px;
}
.candidate-dashboard .fa.fa-check {
  color: #11527e;
  outline: none;
}
.candidate-dashboard button {
  outline: none;
  border: none;
}
.candidate-dashboard .button:hover {
  border: none;
}
.candidate-dashboard .fa.fa-times {
  color: #11527e !important;
  position: relative;
  outline: none;
}
.candidate-dashboard .location-update-text {
  color: #23ccef !important;
}
.hover-link-orange:hover {
  color: #f7931e !important;
}

@media (min-width: 992px) {
  .navbar-nav .nav-item .dropdown-menu,
  .dropdown .dropdown-menu {
    -webkit-transition: all 0ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 0ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 0ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 0ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 0ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    .dropdown-item {
    font-size: 12px;
    color: #888888;
  }
}
@media (max-width: 991px) {
  .candidate-dashboard .sign-out-web {
    display: none;
  }
}
