body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
body {
  min-height: auto;
}
.input-group,
.form-group {
  /*  margin-bottom: 10px;*/
  position: relative;
}
.lbl_info {
  color: #000;
  font-size: 13px;
  font-weight: 600;
}
/* barender - 09-10-20 */
.lbl_info .star_symbol,
.star_symbol {
  color: #f7931e !important;
}
.form-control-error,
.error_class {
  margin-top: 5px;
  font-size: 12px;
  color: #b34040 !important;
  display: block;
  text-align: left;
}
.form-control.error {
  border: 1px solid #b34040 !important;
}
.btn-warning {
  color: #fff;
  background-color: #f7931e;
  border-color: #f7931e;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
/* css for scroll button */
.scrollTopButtonContainer {
  cursor: pointer;
  position: fixed;
  z-index: 99;
  right: 30px;
  bottom: -100px;
  transition: bottom 0.5s;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #12527e;
  color: white;
  padding: 15px;
  border-radius: 4px;
}

.scrollTopButtonContainer button {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #12527e;
  color: white;
}

.scrollTopButtonTransition {
  bottom: 20px;
}
.list-group-item-success {
  color: #101010;
  background-color: #fcfdfc00 !important;
  font-size: 11px;
}
.list-group-item {
  border: none !important;
}
.form-group {
  font-size: 13px;
}
/* .css-12jo7m5 {
  font-size: 75% !important;
} */

/* .css-1uccc91-singleValue {
  font-size: 75% !important;
} */
a.disabled {
  cursor: default;
  color: #6f6f6f !important;
}

/* sidebar Candidat */
.sidebar .sidebar-wrapper .nav .nav-link span {
  color: #2d2822;
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
}
.sidebar .sidebar-wrapper {
  padding-bottom: 0px;
}
.location-input-sidebar {
  width: 103px;
  height: 28px;
  background-color: rgb(255, 255, 255);
  font-size: 11px;
  box-shadow: none;
  color: rgb(227, 227, 227);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(227, 227, 227);
  display: initial;
}
.location-icon-span {
  position: absolute;
  right: 38px;
  margin-top: 5px;
  color: #2d2822;
}

.location-icon-span:hover {
  color: #f7931e;
}
.notice-peroid-input {
  width: 30px;
  height: 28px;
  margin-right: 15px;
  display: initial;
}
.notice-days-left {
  font-size: 18px !important;
  position: relative;
  top: 2px;
}
.zero-opacity a span u {
  font-size: 11px;
  color: #808080;
}
.zero-opacity a span u:hover {
  color: #f7931e;
}
.switch-lable-sidebar {
  margin-left: 14px;
}

.location-img-search {
  position: absolute;
  top: 6px;
  left: 23px;
  color: #2d2822;
}
.card-wizard .nav-tabs .nav-item {
  cursor: pointer;
}
/* .search-select-header>div>div>div{
  padding-left: 22px;
} */
.search-select-header .css-yk16xz-control {
  background-color: #c5525200 !important;
  border-color: rgb(204, 204, 204);
  padding-left: 26px;
}
.css-yk16xz-control {
  background-color: #c5525200 !important;
  border-color: rgb(204, 204, 204);
}
.search-select-header .css-1pahdxg-control {
  padding-left: 26px;
}
.web-view-search .form-group {
  margin-bottom: 0px;
}
.mobile-view-search .form-group {
  margin-bottom: 0px;
}
@media only screen and (min-width: 769px) {
  .pl-0-lg {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 575px) {
  .float-right-lg {
    float: right;
  }
}

@media (max-width: 991px) {
  .width-max-sm {
    width: 100%;
  }
  .background-search-btn {
    background: #ffc30f;
    text-align: center;
  }
  .main-panel .content {
    padding-top: 16px !important;
    padding-bottom: 10px !important;
  }
}
@media (min-width: 992px) {
  .main-panel .content {
    padding: 13px 15px 10px !important;
  }
  .sidebar-mini .sidebar .sidebar-wrapper > .nav li > span .zero-opacity {
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform: translate3d(-25px, 0, 0);
    -o-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper > .nav li > span .zero-opacity {
    opacity: 1 !important;
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .sidebar-mini
    .sidebar:hover
    .sidebar-wrapper
    > .nav
    li
    > span
    .location-icon-span {
    right: 55px !important;
  }
}

/*post job button start*/
.btn-post-job:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}
.btn-post-job:hover {
  background-color: #f4f5f7;
  border-color: #f4f5f7;
}
.btn-post-job:hover {
  text-decoration: none;
}
[class*='btn-post-'] {
  border-width: 1px;
}
.btn-post-job {
  position: relative;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

.navbar-nav .nav-link .nav-link-inner--text {
  margin-left: 0.25rem;
}
.btn-post-job {
  color: #637089;
  border-color: #f4f5f7 !important;
  background-color: transparent;
}
.navbar .navbar-brand:hover {
  background-color: transparent !important;
}
.navbar .navbar-brand {
  background-color: transparent !important;
}
.navbar .btn-post-job {
  margin: 0px;
}
/*post job button end*/

/*Custom Switch start*/

.switch {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 16px;
  top: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #84d344;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
  border: 1px solid #e3e3e3;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/*Custom Switch ends*/

/* jop-post-header-navbar*/
.emp-post-job-header {
  color: #f7931e;
  font-size: 12px;
  font-weight: 600;
}

/* table css*/
.employer-dashboard .table-hover tbody tr:hover {
  background-color: #ebf0e3;
}
.table > thead > tr > th {
  text-align: center;
  vertical-align: middle;
  color: #2c2c2c;
}
.table > tbody > tr > td {
  text-align: center;
}
.table-action .fa {
  font-size: 20px;
}
.fa-iconthumtack {
  color: #3ea9f5;
  font-size: 17px;
  position: relative;
  top: 2px;
  -webkit-transform: rotate(330deg);
  -moz-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  -o-transform: rotate(330deg);
  transform: rotate(330deg);
}
/* badge css*/
.badge-size {
  font-size: 10px;
  border-radius: 100%;
  width: 100%;
  padding: 4px 6px;
}
/* navlink css start*/
.card-wizard .nav-tabs .nav-item .nav-link {
  font-size: 0.8rem;
  font-weight: 400;
}
/*navlink css end */
/*page header*/
.page-header {
  color: #808080;
  font-size: 13px;
}
/* custom class css*/
.text-align-center {
  text-align: center;
}
star {
  color: #f7931e;
  font-size: 1rem !important;
}
.text-blue-standard {
  color: #11527e !important;
}

/* padding 0*/
.padding-zero {
  padding: 0px;
}
.p-t-5 {
  padding-top: 5px;
}
/* font-sizes*/
.font-size-13 {
  font-size: 13px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-9 {
  font-size: 9px;
}
/* modal css  start*/
.modal-header .modal-title {
  padding-left: 30px;
  font-size: 16px;
  color: #808080;
}
.modal .modal-content .modal-body {
  padding-left: 0px;
  padding-right: 0px;
}
.modal-header .modal-title {
  padding-left: 0px !important;
}
.modal .modal-content .modal-header {
  padding-right: 30px;
  padding-top: 30px;
}
.modal .modal-content .modal-body + .modal-footer {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
/* modal css  end*/
/*select textbox css*/

.select-text-table {
  background: transparent;
  border-radius: 4px;
  height: 35px;
  width: 120px;
  padding-left: 10px;
}
/*select textbox css*/
.dropdown-before .dropdown-menu {
  min-width: 100px;
  left: 0px;
  right: 0px;
}
.dropdown-before .dropdown-menu .dropdown-item {
  font-size: 13px;
}
/* filter button css end*/
/*icon hover orange*/
.fa-share-alt:hover,
.fa-star:hover {
  color: #f7931e;
}
/*icon hover orange*/

.confirm-back {
  padding: 5px 30px;
  text-align: center;
}

.confirm-back .message {
  font-size: 12px;
}
.create-candidate .modal-footer {
  padding: 10px 30px 30px !important;
  justify-content: center !important;
}
.create-candidate.modal .modal-content .modal-header {
  padding-top: 25px;
  padding-left: 30px;
}
.create-candidate.modal .modal-content .modal-header .close {
  position: relative;
  top: 4px;
}

.warning {
  width: 100%;
  text-align: center;
  font-size: 15px;
}

.completedStep {
  background-color: #85ea6e !important;
}

.modal_header_txt {
  color: #8f8989;
  font-size: 16px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.txt_gray {
  color: #8f8989;
}

.logo-container {
  padding-left: 30px;
  padding-right: 30px;
}

.select-file {
  font-size: 14px;
}

input[type='radio'] {
  margin-top: -2px;
  vertical-align: middle;
  margin-right: 3px;
}

.radio-inline.lbl_radio_info {
  color: 'gray' !important;
  font-size: 12px;
}

.company__form-label {
  text-transform: none;
  font-size: 12px;
  color: #2c2c2c;
}

.image-cropper .modal-body {
  padding-top: 12px !important;
  padding-bottom: 24px !important;
}

.image-cropper.modal .modal-content .modal-body .cropper {
  padding-bottom: 10px;
}
.btn:disabled {
  opacity: 1;
  background-color: lightgray;
  border-color: lightgray;
}

.radio-inline.lbl_radio_info {
  color: gray !important;
}
.page-item.active .page-link {
  z-index: 0;
}
.modal-header .close {
  font-size: 1.8rem;
  /* margin: 0rem -0.5rem -2rem auto !important; */
  padding: 0px 5px;
  outline: none;
}
.modal-header .close:hover {
  background-color: #bbb;
  border-radius: 50%;
}
@media (max-width: 575px) {
  .modal .modal-content .modal-header {
    padding-left: 30px;
  }

  .candidate-edit-profile .file-field .file-path-wrapper input {
    font-size: 10px !important;
  }
}

/* .modal .modal-content .modal-header */

.contact-info.modal .modal-content .modal-body {
  padding-top: 15px;
  padding-bottom: 15px;
}
.contact-info .modal-dialog p label {
  font-size: 13px;
  color: #f7931e;
  margin-left: 7px;
}
.contact-info .fa {
  color: #11527e;
  padding-right: 10px;
  font-size: 17px !important;
}

.contact-info .modal-dialog p .fa-phone-square {
  position: relative;
  left: 1px;
}
.contact-info .modal-dialog p.d-flex label {
  position: relative;
  bottom: 1px;
}
.contact-info .close {
  position: relative;
  top: 2px;
}
.contact-info {
  padding-right: 0px;
}
.contact-info .modal-content .modal-footer {
  padding-right: 30px;
  padding-top: 20px;
}
.contact-info .modal-content {
  text-align: center;
  padding-right: 15px;
}
.contact-info .modal-content .modal-footer {
  justify-content: center !important;
}
.contact-info.modal .modal-content .modal-header {
  font-size: 13px;
}
.slide-pane__content {
  padding: 0px !important;
}
.location-img-search-mob {
  position: absolute;
  left: 9px;
  bottom: 15px;
}
._YLx_9 {
  left: 25px;
  bottom: 20px !important;
  width: 64px;
}
._1bpcM {
  align-self: flex-start;
  color: #fff;
  background-color: rgb(79, 206, 93);
  margin-left: 0px;
  margin-bottom: 0px;
  height: 50px;
  width: 50px;
  font-size: 28px;
}
._2W95M {
  outline: none !important;
  border: none !important;
  padding: 8px 12px;
}
@media (min-width: 320px) and (max-width: 480px) {
  ._1bpcM {
    margin-left: 0;
  }
  ._bvAzs {
    /* display: block; */
  }
  ._YLx_9 {
    left: 50%;
    left: 5px !important;
    transform: translateX(5%) !important;
    bottom: 10px !important;
  }
  ._2iWL7 {
    width: 300px !important;
  }
}
span.not-updated {
  color: #f7931e !important;
}
label.not-updated {
  color: #f7931e !important;
}
.status {
  text-align: initial !important;
  display: block;
  margin-left: 0px;
  margin-bottom: 0px;
  margin-top: 00px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 150, 136);
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(0, 150, 136);
  border-image: initial;
  text-decoration: none;
  border-radius: 0px;
}
.logo {
  text-align: initial;
}
.slide-pane__close {
  margin-left: 9%;
  width: 100%;
}
.ReactModalPortal .slider-pane-close-button {
  /* padding: 5px 120px; */
  width: 100%;
  border: none;
  font-weight: 600;
  background: lightgrey;
}
.active-text {
  color: #f7931e;
}

.slide-pane__overlay.ReactModal__Overlay--after-open {
  z-index: 9999;
}

.react-datepicker-wrapper {
  width: 100%;
}

.primary-dark {
  background-color: rgb(17, 82, 126);
}

.primary-dark-text {
  color: rgb(17, 82, 126);
}

.primary-dark:hover,
.primary-dark:focus {
  background-color: rgb(17, 82, 126);
}

.border-r-10 {
  border-radius: 10px;
}
