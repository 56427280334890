.set-pin .remember-text {
  font-size: 12px;
  padding-top: 10px;
}

.set-pin .hint-text {
  color: #11527e !important;
  font-size: 10px;
  padding-top: 0px;
}

.set-pin .star_symbol {
  color: #f7931e;
}

.set-pin .otp-input-inner-container .form-group {
  margin-bottom: 0;
}

.set-pin .future-hint-text {
  color: '#23CCEF';
}
