/* layout changes*/
.landing-page-wrapper .container {
  margin: 0 auto;
  font-size: 16px;
}
.landing-page-wrapper .btn-outline-default {
  color: #172b4d;
  border-color: #172b4d;
  background-color: #fff;
}
.landing-page-wrapper .btn-outline-default:hover {
  color: #fff !important;
  background-color: #172b4d !important;
  border-color: #172b4d !important;
}
.landing-page-wrapper h1,
.landing-page-wrapper h2,
.landing-page-wrapper h3,
.landing-page-wrapper h4,
.landing-page-wrapper h5,
.landing-page-wrapper h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #32325d;
}
.landing-page-wrapper .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.25rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.landing-page-wrapper .form-control {
  font-size: 0.875rem;
  transition: all 0.15s ease;
  margin-right: 10px;
}
.landing-page-wrapper .language-widget {
  margin: auto;
  text-align: right;
}
@media (min-width: 768px) {
  .landing-page-wrapper .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .landing-page-wrapper .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .landing-page-wrapper .container {
    max-width: 1040px;
    padding: 0 15px;
  }
}

/* header styling starts*/
.landing-page-header {
  z-index: 10000;
  position: fixed;
  width: 100%;
  flex-flow: column;
  background-color: #fff;
  top: 0;
}

/* footer styling starts*/
.landing-page-wrapper .footerEnd {
  padding: 10px 0;
  text-align: center;
  /* background: #e5eef5; */
}
.landing-page-wrapper footer div {
  text-align: center;
  margin: 0 auto;
}
.landing-page-wrapper .nav-link {
  color: #525f7f !important;
  display: block;
  padding: 0.25rem 0.5rem;
}
.landing-page-wrapper .pl-0,
.landing-page-wrapper .px-0 {
  padding-left: 0 !important;
}
.landing-page-wrapper a {
  color: #11527e;
  text-decoration: none;
  background-color: transparent;
}
.landing-page-wrapper .nav-footer li a {
  font-size: 13px !important;
}
.landing-page-wrapper .social {
  padding: 5px;
  color: #11527e;
}
.landing-page-wrapper .social .fa {
  width: auto;
}
.landing-page-wrapper .padding-footer {
  padding: 0px 10px;
}
.versionmodal {
  z-index: 10000;
}
.versionmodal .table td {
  padding: 10px;
  vertical-align: top;
  border-top: 0.0625rem solid #dee2e6;
}
.versionmodal ol.list-group li:nth-child(even) {
  background: #e3eaef;
}
.versionmodal.modal .modal-content .modal-header {
  padding-top: 20px;
  padding-bottom: 0px;
  padding-left: 30px;
  margin-left: auto;
  margin-right: auto;
}
.versionmodal.modal .modal-content .modal-body {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
}
.versionmodal .table-full-width {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 0px 0px 0px !important;
}
.versionmodal .table.table-hover.table-striped {
  min-width: 430px;
}
.versionmodal thead {
  text-align: center;
}
.versionmodal .table thead th {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 400;
}
.versionmodal .table > tbody > tr > td {
  color: #9a9a9a;
}
.versionmodal .modal-header .close {
  position: absolute;
  right: 30px;
  top: 25px;
}

.versionmodal.modal .modal-dialog {
  padding-top: 130px;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1rem auto;
  }
}

@media (min-width: 800px) {
  .modal-dialog.modal-xl {
    max-width: 800px;
    margin: 1.5rem auto;
  }
}

@media (min-width: 1100px) {
  .modal-dialog.modal-xl {
    max-width: 900px;
    margin: 1.75rem auto;
  }
}
