.c-dashboard .last_logged_bor_bottom {
  border-bottom: 0.5px solid #ddd;
  margin-left: 20px;
  margin-right: 20px;
}

.c-dashboard .slider {
  left: 7px;
}

.c-dashboard .txt_last_login_location {
  padding: 6px 0px 10px 29px;
}
.c-dashboard .user .info ul.nav a {
  display: inline-block;
  padding-right: 0;
  margin-right: 0;
}
.candidate-dashboard a.padding-globe-top {
  padding-top: 8px !important;
}
.c-dashboard .user .info ul.nav .profpercent {
  padding-left: 0;
  margin-left: 0;
}

.c-dashboard .location-input-sidebar {
  height: 28px;
}

.c-dashboard .user .info .name_profile a .caret,
.off-canvas-sidebar .nav .caret {
  position: absolute;
  right: 0;
  top: 16px;
}

.c-dashboard .presentlocation {
  float: left;
  width: 140px;
  position: relative;
  right: 3px;
  display: none;
}

.c-dashboard .presentlocation .location_icon_span {
  position: absolute;
  right: 5px;
  top: 2px;
}

.c-dashboard .sidebarnotificationsettings {
  display: none;
}

.c-dashboard .sidebarnotificationsettings ul {
  padding-left: 45px;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link .btn.btn-info {
  padding: 0px 0px 5px 0px !important;
  width: 25px;
  position: relative;
  top: 5px;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link .btn.btn-info i {
  position: relative;
  right: 3px;
}
.candidate-dashboard .sidebar .sidebar-wrapper .nav .nav-link p {
  bottom: 4px;
}

.sidebar a[data-toggle='collapse'][aria-expanded='true'] .caret,
.sidebar .btn[data-toggle='collapse'][aria-expanded='true'] .caret.down,
.sidebar a.dropdown-toggle[aria-expanded='true'] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(0deg);
}
@media (min-width: 992px) {
  .c-dashboard .sidebar-mini-slip {
    width: 65px;
    display: block;
    font-weight: 200;
    z-index: 3;
    position: fixed;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 991px) {
  .c-dashboard .revised-hide-mob-menu {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(260px, 0, 0) !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 10000 !important;
  }

  .candidate-dashboard .revised-show-mob-menu {
    right: 0 !important;
    left: auto !important;
    transform: none !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 10000 !important;
  }
  .candidate-dashboard
    .user
    .info
    .profile-wrap
    .sidebar-icon-badge
    .fa.fa-id-badge {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .candidate-dashboard
    .user
    .info
    .profile-wrap
    .sidebar-icon-badge
    .fa.fa-id-badge {
    font-size: 14px;
  }
}

.candidate-dashboard .toggle-switch {
  margin-right: 5px;
}

.candidate-dashboard .notice-days-label {
  display: inline-block;
  width: 45px;
  padding-left: 5px;
}

.candidate-dashboard .notice-period-text {
  margin-left: 2px;
}

.candidate-dashboard .sidebar .user .profile-dropdown .sidebar-dropdown:hover {
  color: #f7931e !important;
}

.candidate-dashboard .padding-globe-top {
  padding-top: 7px !important;
}

.candidate-dashboard .sidebar .nav .nav-item .nav-link .new-role-color {
  color: #23ccef !important;
  opacity: 1;
}
.profile-dropdown.profile-completion {
  cursor: context-menu !important;
}
#notice-period-save .btn-info {
  padding: 0px;
  height: 22px;
  background: #fff;
  border: #fff;
}
.sidebar .nav .nav-item .nav-link #notice-period-save .btn-info i {
  width: 22px;
}
.sidebar .nav .nav-item .nav-link #notice-period-save .btn-info i {
  margin-top: 0px;
  margin-right: 0px;
  position: relative;
  bottom: 7px;
}
.candidate-dashboard .fa.fa-check .btn-info {
  margin-right: 5px;
}
@media (max-width: 991px) {
  .sidebar .nav .nav-item .nav-link #notice-period-save .btn-info i {
    position: relative;
    bottom: 1px;
  }
}
.logo .last-logged-label {
  color: #23ccef;
}
.switch-lable-sidebar, .days-notice-sidebar {
  font-size: 11px !important;
}
.switch-lable-sidebar {
  margin-left: 10px !important;
}
