.predashboard-footer .list-group-item-success {
  color: #000;
}

.predashboard-footer a:hover {
  color: #f7931e;
}

.predashboard-footer .list-group-item-footer a {
  color: #11527e;
}

.footer_caret {
  color: #f7931e;
}
