.pre-dashboard-header {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 84px;
}
.pre-dashboard-header .navbar-brand {
  display: inline-block;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  line-height: inherit;
  white-space: nowrap;
}
.pre-dashboard-header .navbar-nav {
  flex-direction: column;
}

.pre-dashboard-header .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 0.9rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  border-radius: 0.25rem;
}
.pre-dashboard-header .navbar-nav .nav-link.logout-btn {
  padding: 0;
  float: right;
  font-weight: 800;
  font-size: 20px;
}
.pre-dashboard-header .user-link {
  text-align: right;
}
.pre-dashboard-header .logout-link {
  font-size: 10px;
  color: #f7931e;
  bottom: 1px;
  padding-left: 5px;
  cursor: pointer;
}
.pre-dashboard-header .user-link a {
  color: #11527e;
  font-size: 13px;
  vertical-align: baseline;
  text-transform: capitalize;
}
.pre-dashboard-header .user-link .login-name {
  color: #11527e;
  font-size: 12px;
  text-transform: capitalize;
}
.pre-dashboard-header .user-link .login-time {
  font-size: 10px;
  color: #808080;
  position: relative;
  bottom: 5px;
}
.pre-dashboard-header .btn.logout {
  background: #11527e;
  color: #fff !important;
  padding: 8px 50px !important;
  text-transform: none;
}
.pre-dashboard-header .dropdown-menu {
  left: auto;
  right: -20px;
}
.pre-dashboard-header .btn {
  position: relative;
  text-transform: uppercase;
  will-change: transform;
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  display: inline-block;
  font-weight: 600;
  color: #525f7f;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  margin: 0;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: all 0.15s ease;
}
.pre-dashboard-header .btn:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
  text-decoration: none;
}
.pre-dashboard-header .btn-outline-secondary {
  color: #637089;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid #f4f5f7;
  padding: 0.625rem 1.25rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: all 0.15s ease;
}

.pre-dashboard-header .btn-default {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.pre-dashboard-header .btn-default:hover {
  color: #fff !important;
  background-color: #0e1b30 !important;
  border-color: #0b1526 !important;
}
.pre-dashboard-header .btn-outline-secondary:hover {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08) !important;
  transform: translateY(-1px) !important;
  color: #212529 !important;
  background-color: #f4f5f7 !important;
  text-decoration: none;
  border-color: #f4f5f7;
}
.pre-dashboard-header .navbar-brand img {
  max-width: 224px;
  padding-top: 12px;
  padding-bottom: 5px;
}
.pre-dashboard-header.navbar-expand-lg {
  flex-flow: column;
}
.pre-dashboard-header.navbar-main > .container {
  padding-left: 1rem;
}
.pre-dashboard-header.navbar-light .navbar-toggler {
  width: auto;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
  display: none;
  padding: 0;
}
.pre-dashboard-header .navbar-collapse .collapse-close {
  text-align: right;
}
.pre-dashboard-header .navbar-collapse .navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;
}
.pre-dashboard-header .navbar-collapse .navbar-toggler :nth-child(1) {
  transform: rotate(135deg);
}
.pre-dashboard-header .navbar-collapse .navbar-toggler :nth-child(2) {
  transform: rotate(-135deg);
}
.pre-dashboard-header .navbar-collapse .navbar-toggler span {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  background: #283448;
}
.pre-dashboard-header .navbar-collapse-header {
  display: none;
}
.beta-top-homepage {
  color: #f7931e;
  font-size: 12px;
  position: relative;
  left: 2px;
  bottom: 6px;
}
@media (max-width: 991px) {
  .pre-dashboard-header.navbar-light .navbar-toggler {
    display: inline-block;
  }
  .pre-dashboard-header .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
    display: block !important;
  }
  .pre-dashboard-header .navbar-collapse {
    position: absolute;
    top: 0;
    width: calc(100% - 1.4rem);
    background: #fff;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;
  }
  .pre-dashboard-header .navbar-collapse .navbar-collapse-header {
    display: block;
  }
  .pre-dashboard-header .navbar-nav .nav-item {
    padding: 0.625rem 0;
  }
  .pre-dashboard-header .navbar-nav .nav-link {
    padding: 0;
    color: #172b4d !important;
    opacity: 1;
    text-align: center;
  }
  pre-dashboard-header .navbar-nav .btn {
    width: 100%;
  }
  .pre-dashboard-header .user-link {
    text-align: center;
    line-height: 1.625rem;
  }
  .pre-dashboard-header .logout-link.logout-sm-none {
    display: none;
  }
  .collapse:not(.show) {
    display: none !important;
  }
  .pre-dashboard-header .m-sm-none {
    display: none;
  }
}
.pre-dashboard-header .navbar-nav .nav-link i {
  margin-right: 0.625rem;
  font-size: 14px !important;
  top: 0 !important;
  width: unset !important;
}
@media (min-width: 992px) {
  .pre-dashboard-header .navbar-nav {
    flex-direction: row;
  }
  .pre-dashboard-header .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
