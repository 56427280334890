.user-agreement.content {
  padding: 0px 15px 0px !important;
  min-height: calc(100vh - 115px - 33px);
}

.user-agreement .row .head-label-text {
  font-size: 12px;
  color: #f7931e;
  text-transform: none;
}
.user-agreement .card-parent {
  background: none;
}
.user-agreement .card {
  margin-bottom: 0px;
}
.user-agreement .nav-container {
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
}
.user-agreement .nav-text,
.nav-icons {
  padding: 0px 0 0;
}
.user-agreement .huge-text {
  font-size: 5vw;
  color: #23ccef;
}
.user-agreement .not-awkward {
  text-align: center;
  font-size: 12px;
}
.user-agreement .not-awkward.social-text {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 7px;
  color: #999292;
}
.user-agreement .text-align {
  text-align: center;
}

.user-agreement .nav-text .nav-item .nav-link,
.nav-icons .nav-item .nav-link {
  padding: 15px 0px 10px;
  text-transform: uppercase;
}
.user-agreement .nav-icons > li > a {
  padding: 0 10px;
  margin-bottom: 0px;
  color: #5c5a5a !important;
  font-weight: 600;
}
.user-agreement .nav-container a:hover {
  border-bottom: 2px solid #f7931e;
  text-decoration: none;
}
.user-agreement .nav-icons .nav-item .nav-link:hover {
  color: #f7931e;
}
.user-agreement .nav-icons .nav-item .nav-link.active {
  color: #11527e !important ;
}
.user-agreement .nav-container a.active {
  border-bottom: 2px solid #11527e;
  text-decoration: none;
}
.user-agreement .nav-container ul li {
  margin: 0 3% 0;
}
.user-agreement .nav-container ul {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  white-space: nowrap;
  display: block !important;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  position: fixed;
  top: 113px;
  z-index: 999;
}
.user-agreement .li-warrent {
  margin-bottom: 0px;
  position: relative;
  right: 14px;
}
.user-agreement .nav-container::-webkit-scrollbar {
  display: none;
}

.user-agreement .static-right-content-box .content-sub-title {
  font-size: 18px;
  font-weight: bold;
}
.user-agreement .card .card-category,
.user-agreement .card label {
  font-size: 11px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 15px;
}
.user-agreement .card label {
  text-transform: none;
  margin: 0;
  padding-top: 10px;
}
.user-agreement .card {
  border-radius: 0px !important;
  border: none;
}
.user-agreement .card .card-title {
  color: #808080;
  font-size: 17px;
}
.user-agreement #description-logo .card .card-body {
  padding: 60px 70px 40px 70px;
}
.user-agreement #contact .card .card-header {
  padding: 20px 30px 40px 70px;
}
.user-agreement .left-parent-box {
  margin-top: 3px;
  padding: 25px 15px 25px 15px;
  border-radius: 10px;
}
.user-agreement .left-parent-box a:hover {
  color: #f7931e;
}
.user-agreement .regular-font {
  color: #2c2c2c !important;
}

.user-agreement p {
  font-size: 12px;
  text-transform: none;
}
.user-agreement .card p {
  line-height: 25px;
  text-align: justify;
}
.user-agreement .scroll {
  white-space: nowrap; /* [1] */
  overflow-x: auto; /* [2] */
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */
}

/* [5] */
.user-agreement .scroll::-webkit-scrollbar {
  display: none;
}
.user-agreement .main-element-label {
  padding-left: 15px;
}
.user-agreement .card label i {
  padding-right: 15px;
}
.user-agreement i.fa {
  color: #999292 !important;
}
.user-agreement .tab-content {
  padding-top: 60px;
}
.user-agreement .fa-bell,
.user-agreement .fa-share-alt,
.user-agreement .fa-star {
  font-size: 0.8em;
  position: relative;
  top: 12px;
}

.user-agreement .fa-bell:hover,
.user-agreement .fa-share-alt:hover,
.user-agreement .fa-star:hover {
  color: #f7931e;
}
.user-agreement .hover-effect:hover {
  color: #f7931e;
}
.user-agreement .pull-right-lg.btn-info:hover {
  color: #fff;
  background: #f7931e;
  border: 1px solid #f7931e;
}
.user-agreement a u {
  text-transform: none;
}
.user-agreement .small-grey-text {
  color: #4e5266;
  text-transform: none !important;
  font-size: 10px !important;
}

.user-agreement .grey-text {
  color: #4e5266;
  text-transform: none !important;
  font-size: 12px !important;
}

.user-agreement .content-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
}

.user-agreement .pagination .page-item .page-link {
  font-size: 13px;
}
.user-agreement .card {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);
}
.user-agreement .sort-button {
  margin-right: 20px;
}
.user-agreement .pos-same-line {
  position: relative;
  bottom: 2px;
}
.user-agreement .card .card-header {
  padding: 50px 70px 0 70px;
}

.user-agreement dl,
.user-agreement ol,
.user-agreement ul {
  margin-top: 0;
  text-transform: none;
  text-align: justify;
  font-size: 12px;
  line-height: 1.7;
  padding-inline-start: 14px;
}
.user-agreement .content-sub-title {
  font-size: 30px;
  line-height: 48px;
  font-weight: 500;
}
.user-agreement .quote-mark {
  min-height: 100%;
  min-width: 5px;
  width: 5px;
  background-color: #ffcc0d;
  margin-right: 15px !important;
}
.user-agreement .h4.display-u2u,
.user-agreement .about-page h4.display-u2u {
  font-size: 18px;
  line-height: 30px;
  margin-top: 20px;
  text-align: justify;
}
.user-agreement h5 {
  color: #11527e;
}
.user-agreement #description-logo h5,
.user-agreement h2,
.user-agreement h3 {
  color: #11527e;
  font-size: 30px;
}
.user-agreement h6.content-sub-title {
  font-size: 30px;
  line-height: 48px;
  font-weight: 500;
  color: #23ccef;
}
.user-agreement .mb-huge-text {
  margin-bottom: 120px;
  margin-top: 120px;
}
.user-agreement .contact-social .social-list {
  margin-left: -2px;
  margin-bottom: 30px;
}
.user-agreement .contact-social .social-list li {
  font-size: 20px;
  margin-right: 30px;
}
.user-agreement .social-list {
  margin-top: 0em;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
  -ms-flex-direction: row;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  box-pack: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.user-agreement .js-contact-social h3 {
  margin-bottom: 0px;
  text-align: center;
  font-size: 15px;
  margin-top: 50px;
}
.user-agreement .card .card-body {
  padding: 30px 70px 40px 70px;
}
.user-agreement .card-header label {
  font-size: 11px;
}
.user-agreement .card.card-wizard.main-block {
  min-height: 500px;
}
.user-agreement .mail-id-support {
  color: #23ccef;
}
.user-agreement .com-address-contact {
  color: #808080;
  font-size: 11px;
}
@media (min-width: 576px) {
  .user-agreement .ml-auto-lg {
    margin-left: auto;
  }
  .user-agreement .pos-job {
    position: relative;
    bottom: 8px;
  }
  .user-agreement .padd-rt-jobs {
    padding-left: 15px;
  }

  .user-agreement .content .pt-label-lg {
    padding-top: 5px;
  }
  .user-agreement .tab-content {
    padding-top: 60px;
    padding-left: 18px;
    padding-right: 16px;
  }
}
@media (max-width: 575px) {
  .user-agreement .row .adjust-bottom-padding {
    padding-bottom: 10px;
  }
  .user-agreement .pos-job {
    padding-bottom: 10px;
  }
  .user-agreement .padd-sm-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .user-agreement .adjust-bottom {
    padding-bottom: 10px;
  }
  .user-agreement .padding-bottom-adjustment {
    padding-bottom: 0;
  }
  .user-agreement .card .card-body {
    padding: 20px 30px 30px 30px;
  }
  .user-agreement .card .card-header {
    padding: 30px 30px 0 30px;
  }

  .user-agreement #description-logo .card .card-body {
    padding: 30px 40px 0px 40px;
  }
}

@media (min-width: 992px) {
  .user-agreement .pull-right-lg {
    float: right;
  }
  .user-agreement .list-unstyled {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .user-agreement .pl-main-block-web {
    padding-left: 5px;
  }
}
@media (max-width: 767px) {
  .user-agreement .parent-column-box {
    padding-left: 0px;
    padding-right: 0px;
  }

  .user-agreement .left-parent-box {
    margin: 0;
    padding: 20px 0 0 0;
  }

  .user-agreement .container .container-fluid {
    padding: 15px;
  }

  .user-agreement .sort-button {
    margin-right: 0px;
  }

  .user-agreement .sub-container {
    padding: 0px;
  }

  .user-agreement .content-box {
    margin: 5px 0px 10px 0;
    padding: 15px 15px;
  }

  .user-agreement .right-content-box {
    padding: 0 15px;
  }

  .user-agreement .container-fluid .text-blue-standard {
    padding-right: 0 !important;
  }
  .user-agreement .padd-sm-25 {
    padding-left: 25px;
    padding-right: 25px;
  }
  .user-agreement .padd-ltrt-zero-sm {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media screen and (max-width: 900px) {
  .user-agreement .huge-text {
    font-size: 3.3em !important;
  }
}
@media screen and (max-width: 768px) {
  .user-agreement .huge-text {
    font-size: 2.2em !important;
  }
}

@media screen and (max-width: 500px) {
  .user-agreement .huge-text {
    font-size: 1.6em !important;
  }
}
@media screen and (max-width: 350px) {
  .user-agreement .huge-text {
    font-size: 1.3em !important;
  }
}
