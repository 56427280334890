/* css for react toastify */
.Toastify__toast-container {
  font-size: 0.85rem;
  z-index: 999999 !important;
  width: auto;
  padding: 4px;
}
@media (max-width: 575px) {
  .Toastify__toast-container {
    width: 100vw;
  }
}
.Toastify__toast--info {
  background: #e5e3ef !important;
  text-align: center !important;
  color: black;
  width: 380px;
}
.Toastify__toast--info .Toastify__close-button {
  color: #007bff;
}
.Toastify__toast--info .Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 9999;
  opacity: 0.7;
  background-color: #007bff;
  transform-origin: left;
}
.Toastify__toast--success {
  background: #85ea6e !important;
  text-align: center !important;
}
.Toastify__toast--warning {
  background: orange !important;
  text-align: center !important;
}
.Toastify__toast--danger {
  background: #e74c3c !important;
  text-align: center !important;
}
.Toastify__toast {
  min-height: 37px !important;
  padding: 15px;
  text-align: justify !important;
}
.Toastify__close-button > svg {
  margin-left: 10px;
}
.Toastify__toast-body {
  padding-right: 15px;
}
