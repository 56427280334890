.candidate_view_profile_details .card-wizard .nav-tabs .nav-item {
  width: 33.33%;
}
.candidate_view_profile_details .card-wizard .nav-tabs .nav-fifty {
  width: 50% !important;
}
.candidate_view_profile_details .card .card-title {
  color: #808080;
  font-size: 17px;
}
.candidate_view_profile_details .card .card-header {
  border-radius: 10px;
  padding-bottom: 0px;
  padding-left: 40px;
  padding-top: 15px !important;
}
.candidate_view_profile_details .card .card-body {
  padding: 15px 15px 10px 15px;
}
.candidate_view_profile_details .candidate-name {
  color: #23ccef !important;
  font-size: 12px !important;
}
.candidate_view_profile_details .card-wizard {
  border: none;
  border-radius: 10px;
}
.candidate_view_profile_details .card label {
  text-transform: none;
  margin: 0;
  padding-top: 0px;
}
.candidate_view_profile_details .card .card-header {
  border-radius: 10px;
}
.candidate_view_profile_details .company-logo-img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 0.5px solid #efefef;
  margin-top: 6px;
}
.candidate_view_profile_details .card label {
  text-transform: none;
  font-size: 12px;
  color: #2c2c2c;
}
.candidate_view_profile_details .background-color span {
  font-size: 12px;
  color: #9a9a9a;
}
.candidate_view_profile_details #collapseone label {
  font-size: 12px;
}
.candidate_view_profile_details .status-text {
  padding-left: 15px;
}
.candidate_view_profile_details .row .text-gary-small {
  font-size: 0.7em !important;
  color: #999292 !important;
}
.candidate_view_profile_details .text-blue-small {
  color: #2c2c2c;
  font-size: 11px !important;
}
.modal.welcome-modal .modal-content .modal-body .text-small {
  font-size: 11px !important;
  color: #9a9a9a !important;
}
.candidate_view_profile_details .text-blue-sm {
  color: #11527e;
  font-size: 0.7em !important;
}
.candidate_view_profile_details .fa-download {
  position: relative;
  top: 1.5px;
  right: 0.5px;
}
.candidate_view_profile_details .sub-div .fa-download {
  top: 0px;
}
.candidate_view_profile_details .fa {
  display: inline-block;
}
.candidate_view_profile_details .pos-sm-btn:hover {
  background-color: #f7931e;
  border: #f7931e;
}
.candidate_view_profile_details .sub-div-title {
  font-size: 11px !important;
  color: #23ccef;
  text-transform: none;
  font-weight: 400;
  padding-bottom: 5px;
}
.candidate_view_profile_details span.sub-div-title {
  color: #23ccef !important;
}
.candidate_view_profile_details .cv-color {
  color: #23ccef !important;
}
.candidate_view_profile_details .div-1 label {
  width: 50%;
}
.candidate_view_profile_details .card .sub-div label {
  text-transform: none;
  font-size: 12px;
  color: #2c2c2c;
  padding-top: 12px;
}
.candidate_view_profile_details .sub-div span {
  font-size: 12px;
  color: #9a9a9a;
}
.candidate_view_profile_details .row .text-orange {
  color: #f7931e !important;
  font-size: 8px;
}
.candidate_view_profile_details .fa-floppy-o {
  color: #f7931e !important;
}
.candidate_view_profile_details .table-action .fa {
  font-size: 28px;
}
.candidate_view_profile_details .can-id-row {
  background: #f9f9f9;
  padding-top: 15px;
  padding-bottom: 0px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.candidate_view_profile_details .pad-sm-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.candidate_view_profile_details .fa-download,
.candidate_view_profile_details .fa-map-marker,
.candidate_view_profile_details .fa-globe {
  color: #f7931e !important;
  margin-right: 3px;
}
.candidate_view_profile_details .fa-map-marker {
  margin-right: 2px;
}
.candidate_view_profile_details .fa-globe,
.candidate_view_profile_details .fa-map-marker {
  position: relative;
  /* top: 1px; */
}
.candidate_view_profile_details .fa-bolt {
  position: relative;
  top: 0px;
  font-size: 12px;
  left: 2px;
  color: #f7931e !important;
}
.candidate_view_profile_details .exp-details {
  font-size: 13px !important;
  font-weight: 400;
  color: #2c2c2c;
  text-transform: none;
}
.candidate_view_profile_details .last-logged-date {
  padding-left: 3px;
}
.candidate_view_profile_details .pos-loc-full-app {
  position: relative;
}

.candidate_view_profile_details .fa-briefcase,
.candidate_view_profile_details .fa-money,
.candidate_view_profile_details .fa-bookmark-o {
  position: relative;
  /* top: 1.4px; */
  margin-right: 3px;
  font-size: 14px;
}
.candidate_view_profile_details .fa-money {
  margin-right: 5px !important;
}
/* .candidate_view_profile_details .fa-briefcase {
  right: 2px;
}
.candidate_view_profile_details .fa-bookmark-o {
  right: 3.4px;
} */
.candidate_view_profile_details .pt-role {
  padding-top: 6px !important;
}
.candidate_view_profile_details .pt-experience {
  padding-top: 0;
  padding-bottom: 0;
}

.candidate_view_profile_details .background-color {
  background-color: #f8f9fa !important;
  padding: 7px 0px 8px 0px;
}
.candidate_view_profile_details .background-color .pt-label {
  padding-top: 12px;
}
.candidate_view_profile_details .background-color .pb-label {
  padding-bottom: 12px;
}
.candidate_view_profile_details .btn-info:hover {
  background-color: #f7931e;
  border-color: #f7931e;
}
.candidate_view_profile_details .edit-job-preference {
  position: absolute;
  right: 15px;
  bottom: 3px;
  color: #c3bdbd;
}
.candidate_view_profile_details .edit-job-preference-course {
  position: absolute;
  right: 15px;
  bottom: 8px;
  color: #c3bdbd;
}
.candidate_view_profile_details .text-orange-small {
  color: #f7931e;
}
.candidate_view_profile_details .text-gary-small-date {
  padding-left: 4px;
  color: #999292;
  font-size: 8px;
}
.candidate_view_profile_details .card-wizard .nav-tabs {
  margin-top: 12px;
}
@media (max-width: 575px) {
  .candidate_view_profile_details .adjust-left-top {
    padding-top: 10px;
    padding-bottom: 3px;
  }
  .candidate_view_profile_details .adjust-right-top {
    top: -10px;
  }

  .candidate_view_profile_details .sub-div {
    padding: 5px 0px;
  }
  .candidate_view_profile_details .tab-pane {
    padding: 5px 10px !important;
  }
  .candidate_view_profile_details .width-full {
    float: left;
  }
  .candidate_view_profile_details .pos-app-on-sm {
    position: absolute;
    right: 20px;
    bottom: 1px;
  }
  .candidate_view_profile_details .padd-id-middle {
    padding-left: 4px;
    padding-top: 2px;
  }
  .candidate_view_profile_details .main-div {
    padding-left: 10px;
    padding-right: 0px;
  }
  .candidate_view_profile_details .padd-top-sm {
    padding-bottom: 6px;
    padding-top: 7px;
  }
  .candidate_view_profile_details .pos-top-avail {
    margin-top: -100px;
  }
  .candidate_view_profile_details .padd-sm-tb {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .candidate_view_profile_details .select-dropdown-full-app {
    margin-bottom: 13px;
    margin-top: 10px;
    padding-right: 25px;
  }
  .candidate_view_profile_details .status-text {
    padding-left: 0;
  }

  .candidate_view_profile_details .adjust-top {
    top: -21px;
    margin-right: 10px;
  }

  .candidate_view_profile_details p {
    margin-bottom: 0;
    position: relative;
    top: 2px;
  }
  .candidate_view_profile_details .edit-profile-pos-sm {
    position: relative;
    top: 11px;
  }
  .candidate_view_profile_details .card .card-body {
    padding-top: 0px;
  }
  .candidate_view_profile_details .pos-sm-btn-mob {
    position: Absolute;
    right: 15px;
    top: 5px;
  }
  .candidate_view_profile_details .nav-link {
    display: block;
    padding: 0.5rem 0rem;
  }
  .candidate_view_profile_details .padd-last-logged {
    padding-right: 22px;
  }
  .background-color {
    background-color: #f8f9fa !important;
    padding: 7px 0px 7px 0px;
  }
  .candidate_view_profile_details .display-block-mob {
    display: block;
    padding-top: 3px !important;
  }
  .candidate_view_profile_details .pb-mob-view {
    padding-bottom: 6px;
  }
  .candidate_view_profile_details .fa-download,
  .candidate_view_profile_details .fa-map-marker,
  .candidate_view_profile_details .fa-globe {
    margin-right: 3px !important;
  }
  .candidate_view_profile_details .pl-industry {
    padding-left: 25px;
  }
  .candidate_view_profile_details .pl-location-mob {
    padding-left: 25px;
  }
  .pl-download-resume {
    padding-left: 15px;
    padding-bottom: 10px;
  }
  .candidate_view_profile_details .pos-sm-btn-web {
    display: none;
  }
  .candidate_view_profile_details .pl-location {
    padding-left: 25px;
  }
  .candidate_view_profile_details .card .card-header {
    padding-left: 25px;
  }
  .candidate_view_profile_details .edit-profile-pencil {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  .candidate_view_profile_details .ribbon-background {
    background-color: #f8f9fa !important;
    margin: -2px -25px 20px;
    padding: 30px 10px 25px;
  }
  .candidate_view_profile_details .ribbon-background-last {
    background-color: #f8f9fa !important;
    margin: 15px -25px 0px;
    padding: 25px 10px 25px;
  }
  .candidate_view_profile_details .ribbon-background-last-helth {
    background-color: #f8f9fa !important;
    margin: -15px -40px 0px;
    padding: 30px 25px 25px;
  }
  .candidate_view_profile_details .ribbon-background-emptab-last {
    background-color: #f8f9fa !important;
    margin: 20px -40px 20px;
    padding: 30px 25px 25px;
  }
}

@media (min-width: 576px) {
  .candidate_view_profile_details .company-logo-img {
    margin-left: 15px;
  }
  .candidate_view_profile_details .padd-id-middle {
    padding-left: 18px;
    padding-top: 2px;
  }
  .candidate_view_profile_details .sub-div-2 {
    position: relative;
    top: 17px;
  }
  .candidate_view_profile_details .sub-div {
    padding: 5px 0px;
  }
  .candidate_view_profile_details .tab-pane {
    padding: 15px 25px;
  }
  .candidate_view_profile_details .main-div {
    padding-left: 25px;
    padding-right: 25px;
  }
  .candidate_view_profile_details .pos-app-on-sm {
    position: absolute;
    right: 26px;
  }
  .candidate_view_profile_details .pos-photo-web {
    position: relative;
    bottom: 5px;
  }
  .candidate_view_profile_details .edit-profile-pos-sm {
    position: relative;
    top: -2px;
  }
  .candidate_view_profile_details .active-profile-pl {
    padding-left: 15px;
    margin-bottom: 6px;
  }
  .candidate_view_profile_details .padd-top-sm {
    position: relative;
    bottom: 7px;
    right: 20px;
  }
  .candidate_view_profile_details .padd-last-logged {
    padding-left: 4px;
  }
  .candidate_view_profile_details .pl-industry {
    padding-left: 40px;
  }
  .candidate_view_profile_details .pos-sm-btn-mob {
    display: none;
  }
  .candidate_view_profile_details .edit-profile-pencil {
    position: absolute;
    right: 40px;
    top: 20px;
  }
  .candidate_view_profile_details .ribbon-background {
    background-color: #f8f9fa !important;
    margin: -12px -40px 20px;
    padding: 30px 25px 25px;
  }
  .candidate_view_profile_details .ribbon-background-last {
    background-color: #f8f9fa !important;
    margin: 15px -40px 0px;
    padding: 25px 25px 25px;
  }
  .candidate_view_profile_details .ribbon-background-last-helth {
    background-color: #f8f9fa !important;
    margin: 0px -40px 0px;
    padding: 30px 25px 25px;
  }
  .candidate_view_profile_details .ribbon-background-emptab-last {
    background-color: #f8f9fa !important;
    margin: 20px -40px 20px;
    padding: 30px 25px 25px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 991px) {
  .candidate_view_profile_details .card-wizard .nav-tabs .nav-item .nav-link {
    font-size: 0.7rem;
    font-weight: 400;
  }
}
@media (max-width: 420px) {
  .candidate_view_profile_details .card-wizard .nav-tabs .nav-item .nav-link {
    font-size: 0.7rem;
    font-weight: 400;
  }
}
@media (max-width: 380px) {
  .candidate_view_profile_details .card-wizard .nav-tabs .nav-item .nav-link {
    font-size: 0.6rem;
    font-weight: 400;
  }
}
.welcome-modal.modal .modal-content .modal-body {
  padding-top: 24px;
  padding-right: 30px;
  padding-bottom: 10px;
  padding-left: 30px;
  line-height: 1.9;
}
.welcome-modal.modal .modal-content .modal-footer {
  padding: 10px 0px 20px;
}
.welcome-modal.modal .modal-content .modal-footer1 {
  padding: 10px 0px 20px;
}
.modal.welcome-modal .modal-content .modal-header {
  padding: 30px 30px 0px;
  font-size: 1rem;
}
.modal.welcome-modal .modal-content .modal-header .close {
  position: relative;
  top: 3px;
}
.modal.welcome-modal .modal-content .modal-body .lbl_info {
  font-weight: 400;
}
.modal.welcome-modal .modal-content .modal-body .meta_div {
  border: 1px solid #ddd;
  border-radius: 10px;
}
