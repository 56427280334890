.predashboard-header-container {
  z-index: 10000;
  position: fixed;
  width: 100%;
  flex-flow: column;
  background-color: #fff;
  top: 0;
}
.predashboard-page-wrapper .language-container {
  min-height: 36px;
}
.predashboard-page-wrapper .language-widget {
  margin: auto;
  text-align: right;
}
.predashboard-page-wrapper .lbl_info {
  font-weight: 400;
}
.predashboard-page-wrapper .dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.predashboard-page-wrapper .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.predashboard-page-wrapper .dropdown-menu .dropdown-item:hover,
.predashboard-page-wrapper .dropdown-menu .dropdown-item:focus {
  color: orange !important;
  opacity: 1;
  text-decoration: none;
  background-color: #ffffff;
  font-weight: bold;
}
.predashboard-page-wrapper .dropdown-menu .dropdown-item {
  padding: 17px 10px;
  text-align: center;
}
.predashboard-page-wrapper .navbar-nav .nav-item .dropdown-menu:before {
  right: 12px;
  left: auto;
}
.predashboard-page-wrapper .navbar-nav .nav-item .dropdown-menu:after {
  right: 12px;
  left: auto;
}

.pre-dashboard-content {
  margin-top: 113px;
  background: #f4f4f3;
  min-height: calc(100vh - 115px - 165px);
}
.container-login .wrap-login_new_use,
.wrap-login_new_use_container,
.container-login .set-pin-wrap-login {
  max-width: 460px;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 7px #98979c;
  padding: 30px 35px;
  margin: 0 auto;
}
.container-login .txt_header_pin_cnp {
  color: #8f8989;
  font-size: 12px;
  margin: 20px 0px 15px 0px;
}
.container-login .login_btn:hover {
  background: #11527e;
}
.login_btn,
.login_btn:hover,
.login_btn:active {
  outline: 0;
}
.wrap-login_new_use_pin .register_user {
  color: #8f8989;
  font-size: 12px;
}
.wrap-login_new_use_pin .txt_pin_forgot_log_ext_user {
  color: gray;
  font-size: 11px;
}
.container-login .tisa_txt_develop_world {
  font-size: 1rem;
  color: #12527e;
  padding-top: 0.5rem;
}
.container-login .form-change-button {
  font-size: 0.7rem;
  cursor: pointer;
}
.container-login .txt_terms_conditions {
  color: #cec3c3;
  font-size: 12px;
}
.container-login .txt_terms_conditions span {
  position: relative;
  top: 3px;
}
.container-login .txt_otp_info_create_pin {
  font-size: 13px;
  padding-left: 0px;
}
.container-login {
  padding: 70px 14px;
}
.login_btn {
  padding: 5px 17px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  outline: none;
  background: #f8931f;
  color: #fff;
  border: none;
  border-radius: 12px;
  box-shadow: 0 2px #999;
  height: 37px;
}
.login_btn:hover,
.login_btn:focus {
  background-color: #12527e;
}
.login_btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.login_btn:disabled:hover {
  background-color: #f8931f;
}
.container-login .txt_worth_more {
  font-size: 14px;
  color: #12527e;
}
.otp-input-outer-container {
  width: 196px;
  overflow: hidden;
}
.otp-input-inner-container {
  left: 0;
  position: sticky;
}
.otp-input {
  text-indent: 6px;
  letter-spacing: 24px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 33px 1px;
  background-repeat: repeat-x;
  background-position-x: 24px;
  min-width: 283px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.pin-input {
  text-indent: 10px;
  letter-spacing: 26px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 33px 1px;
  background-repeat: repeat-x;
  background-position-x: 24px;
  min-width: 283px;
}
.pin-input {
  min-width: 220px;
  letter-spacing: 46px;
  background-size: 50px 1px;
  background-position-x: 36px;
}
.otp-input:focus {
  outline: none;
}
.txt_login_via_otp a {
  color: #11527d;
  font-size: 12px;
}
.txt_otp_info {
  font-size: 14px;
}
.txt_sign_up_footer {
  font-size: 12px;
  font-style: italic;
}
/* footer styling starts*/
.predashboard-footer {
  border-top: 2px solid #dad9d938;
  background: white;
}
.predashboard-footer .web_view_footer {
  padding-bottom: 18px;
}
.predashboard-footer .footer_header_menu h5 {
  padding: 0px 10px;
}
.predashboard-footer .footer_header_menu .nav {
  flex-direction: column;
}
.predashboard-footer .footer_header_menu .footer_header {
  color: #000;
  font-size: 11px;
}
.predashboard-footer .footer_header_menu .nav a {
  display: inline-block;
  padding: 5px 10px;
  color: #636060;
  text-decoration: none;
  font-size: 12px;
}
.predashboard-footer .footer_copyright {
  background-color: #f9f9f9;
  color: #11527e;
  line-height: 32px;
  font-size: 12.5px;
  margin-bottom: 0;
}
.predashboard-footer .list-group-item {
  border: none !important;
  font-size: 11px;
}
.container-login .row-group {
  margin-left: 0px;
  margin-right: 0px;
}
.container-login .login-header {
  font-size: 14px;
  height: 25px;
  margin-top: 9px;
  position: relative;
  text-align: center;
   width: 100%;
  z-index: 999;
}
.container-login .form-change {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background: #e4e4e4;
  box-shadow: 0 4px 8px 0 rgba(21, 21, 21, 0.2);
  color: rgb(7, 7, 7);
  font-size: 16px;
  cursor: pointer;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.container-login .form-change:hover {
  box-shadow: 0 2px 4px 0 rgba(21, 21, 21, 0.2);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.container-login .form-change:hover {
  box-shadow: 0 0px 0px 0 rgba(21, 21, 21, 0.2);
  background: #a9a9a9;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.container-login .country-code-select[disabled] {
  background-color: #ffffff;
  border: 0;
  font-size: 0.8rem;
  color: #5b5858;
  min-width: 67px;
}

@media (max-width: 360px) {
  .container-login .wrap-login_new_use {
    padding: 30px 35px;
  }
}
@media (max-width: 360px) {
  .container-login .wrap-login_new_use {
    padding: 30px 35px;
  }
}
@media screen and (min-width: 767px) {
  .predashboard-footer .mobile_view_footer {
    display: none;
  }
}
@media screen and (min-width: 575px) {
  .container-login .country-code-select {
    max-width: 20%;
  }
}
@media screen and (max-width: 766px) {
  .predashboard-footer .web_view_footer {
    display: none;
  }
  .container-login {
    padding: 40px 15px;
  }
  .container-login .wrap-login_new_use .form-change {
    max-width: 150px;
    margin: 20px 10px;
  }
  .container-login .wrap-login_new_use .form-change {
    max-width: 150px;
    margin: 20px 10px;
  }
  .container-login .row-group input, .container-login .row-group select {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
/* .predashboard-page-wrapper ._YLx_9 {
  left: 15px;
  bottom: 170px !important;
}
.predashboard-page-wrapper ._1bpcM {
  align-self: flex-start;
  color: #fff;
  background-color: rgb(79, 206, 93);
  margin-left: 0px;
  margin-bottom: 0px;
}
.predashboard-page-wrapper ._2W95M {
  outline: none !important;
  border: none !important;
  padding: 8px 12px;
}
@media (min-width: 320px) and (max-width: 480px) {
  .predashboard-page-wrapper ._1bpcM {
    margin-left: 0;
  }
  .predashboard-page-wrapper ._bvAzs {
    display: block;
  }
  .predashboard-page-wrapper ._YLx_9 {
    left: 50%;
    left: 0 !important;
    transform: translateX(5%) !important;
    width: 90%;
  }
} */
