#yt-widget .yt-listbox__cell .yt-listbox__text {
  display: none;
}
#yt-widget .yt-listbox__col {
  display: inline !important;
}
#yt-widget .yt-listbox {
  right: 0;
  height: 136px;
  overflow: scroll;
}
#yt-widget,
#yt-widget * {
  font-family: 'Open Sans', sans-serif !important;
}
#yt-widget.yt-state_mobile .yt-listbox {
  top: unset !important;
  left: unset !important;
  bottom: unset !important;
  margin: 5px 0 0 !important;
  border: none !important;
  position: absolute !important;
  overflow-y: auto !important;
  box-shadow: none !important;
}
#yt-widget.yt-state_mobile .yt-listbox__text {
  line-height: 35px !important;
}
#yt-widget.yt-state_mobile .yt-button_type_close {
  display: none !important;
}
input[value='en'] ~ span,
input[value='ur'] ~ span,
input[value='te'] ~ span,
input[value='ml'] ~ span,
input[value='hi'] ~ span,
input[value='kn'] ~ span,
input[value='mr'] ~ span,
input[value='pa'] ~ span,
input[value='ta'] ~ span,
input[value='gu'] ~ span,
input[value='bn'] ~ span {
  display: block !important;
}
.language-container {
  background-color: #ebf0e3;
  width: 100%;
  min-height: 35px;
}
.language-container .yt-wrapper_align_right {
  display: none;
}
