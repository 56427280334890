/*sidebar css start*/

.candidate-dashboard .logo .logo-mini img {
  width: 32px;
  height: 32px;
  margin-top: 5px;
}
.candidate-dashboard .breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fffb3424;
  border-radius: 0.25rem;
}
.candidate-dashboard .sidebar .logo .logo-normal img {
  padding-top: 5px;
}
.candidate-dashboard .sidebar .logo a.logo-mini {
  margin-left: 15px;
}

.candidate-dashboard .sidebar .logo a.mt-globe-sidebar {
  position: absolute;
  left: 0px;
  bottom: 5px;
}
@media (min-width: 992px) {
  .candidate-dashboard .sidebar-mini .sidebar:hover {
    background: #ffffff;
  }
  .sidebar-mini .sidebar:hover .collapse .sidebar-normal {
    color: #808080 !important;
  }
}
@media (min-width: 992px) {
  .candidate-dashboard .sidebar-mini .sidebar:hover .sidebar-normal {
    color: #2d2822;
  }
  .sidebar-mini .sidebar:hover .sidebar-normal {
    padding-left: 15px;
    color: #2d2822;
  }

  .sidebar-mini .sidebar .user .info .profile-wrap {
    display: none;
  }
  .candidate-dashboard .sidebar-normal-revised {
    left: 0px !important;
  }
}
.candidate-dashboard .sidebar .logo a.last-logged-date {
  font-size: 9px;
  color: #66615b;
  text-transform: none;
  padding-left: 3px;
}
.candidate-dashboard .sidebar .sidebar-wrapper .user .info .profile-name {
  left: 2px;
  color: #f7931e;
  font-size: 12px;
}

.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle='collapse']
  ~ div
  > ul
  > li
  > a
  .sidebar-normal {
  /* font-weight: 400; */
  font-size: 11px;
}
.candidate-dashboard .sidebar .user .profile-dropdown .sidebar-mini {
  font-size: 12px;
}
.candidate-dashboard .sidebar .user .profile-dropdown {
  font-size: 14px;
}
.candidate-dashboard .sidebar .user .profile-dropdown .sidebar-mini .fa {
  font-size: 13px;
}
.candidate-dashboard
  .sidebar
  .user
  .profile-dropdown
  .sidebar-mini.sidebar-icon-badge
  .fa.fa-id-badge {
  font-size: 14px;
}
.candidate-dashboard .sidebar .user .profile-dropdown .sidebar-normal {
  left: 15px;
  font-size: 11px;
  bottom: 2px;
}
.candidate-dashboard .sidebar .user .info a {
  color: #11527e;
  padding: 3px 14px 0px;
  line-height: 24px;
}
.candidate-dashboard
  .sidebar
  .info
  [data-toggle='collapse']
  ~ div
  > ul
  > li:first-child
  > a {
  margin-top: 10px;
  padding-left: 17px;
}
.candidate-dashboard .sidebar-mini .sidebar:hover .user .info a {
  transition: all 300ms linear;
  cursor: pointer;
}

.candidate-dashboard .sidebar .user .info .collapse a {
  color: #808080;
  padding: 4px 14px 0px;
}
.candidate-dashboard .sidebar .user .profile-dropdown {
  padding-left: 17px !important;
}
.candidate-dashboard .sidebar .sidebar-wrapper .nav .nav-link p {
  color: #2d2822;
  font-weight: 400;
  font-size: 11px;
  bottom: 2px;
  text-transform: none;
  right: 3px;
}
.candidate-dashboard .sidebar .sidebar-wrapper .nav .nav-link .add-role-text {
  color: #f7931e;
}
.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .nav
  .nav-link
  .fa.fa-plus-square-o {
  color: #f7931e;
}
.sidebar-mini .sidebar .sidebar-wrapper .nav .nav-link p.add-role-text:hover {
  color: #f7931e;
}
.candidate-dashboard .hover-link-orange:hover {
  color: #f7931e;
}
.candidate-dashboard .sidebar .logo .simple-text {
  color: #2d2822;
}
.candidate-dashboard .sidebar .logo .simple-text .fa {
  font-size: 18px;
}
.candidate-dashboard .sidebar:before {
  opacity: 1;
  background: #ffffff;
  border-right: 1px solid #ddd;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link {
  color: #2d2822;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link i {
  color: #11527e;
}

.candidate-dashboard .sidebar .logo {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.candidate-dashboard .sidebar .user .photo {
  margin-left: 15px;
  position: relative;
  bottom: 2.3px;
}

.candidate-dashboard .sidebar .user {
  /* border-bottom: 1px solid #ddd; */
  margin-top: 12px;
  padding-bottom: 3px;
}
.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .user
  .info
  .sidebar-normal
  .candidate-profile-completion {
  color: #f7931e;
  display: inline-block !important;
}
.candidate-dashboard .dash_profile_percent {
  color: #11527e !important;
}
.candidate-dashboard .dash_profile_percent_orange {
  color: #f7931e;
  display: inline-block !important;
}
.candidate-dashboard .sidebar .user .info .dash_profile_percent {
  display: inline-block;
  font-size: 11px;
}

.candidate-dashboard .sidebar .nav .nav-item .nav-link:hover p {
  color: #f7931e;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link:hover {
  color: #f7931e;
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link i {
  font-size: 0.9rem;
  margin-top: 0px;
}
.candidate-dashboard
  .sidebar
  a[data-toggle='collapse'][aria-expanded='true']
  .caret,
.sidebar .btn[data-toggle='collapse'][aria-expanded='true'] .caret.down,
.sidebar a.dropdown-toggle[aria-expanded='true'] .caret {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  transform: rotate(0deg);
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link {
  padding: 0px;
}
.candidate-dashboard .sidebar .user .info .caret {
  top: -2px;
  position: absolute;
  right: 10px;
}

.sidebar-mini .sidebar:hover .user .info .profile-wrap {
  display: initial;
}
.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .nav
  .nav-link
  p.dashboardlink-sidebar {
  color: #23ccef !important;
}
@media (max-width: 991px) {
  .candidate-dashboard
    .nav-mobile-menu
    .dropdown
    .dropdown-menu
    .dropdown-item {
    color: #2d2822 !important;
  }
  .sidebar-mini .sidebar:hover .user .info .caret {
    position: absolute;
    /* left: 186px; */
    top: -2px;
  }
  .candidate-dashboard .sidebar .user .info .caret {
    top: -2px;
    position: absolute;
    right: 10px;
  }
  .candidate-dashboard .sidebar .nav .nav-item .nav-link i {
    float: left;
  }
  .candidate-dashboard .sidebar .sidebar-wrapper .nav .nav-link p {
    bottom: 3px;
  }
}

/*pricing img start*/

/*pricing img end*/

/*sidebar css ends*/

/*header css start*/

.candidate-dashboard .navbar .navbar-nav .nav-item .nav-link [class^='fa'] {
  font-size: 16px;
  margin-top: -1px;
}

.candidate-dashboard .navbar .navbar-nav .nav-item .nav-link:hover {
  color: #f7931e;
}
.candidate-dashboard .navbar-nav .nav-item .nav-link .role-change-color {
  color: #11527e;
  font-size: 14px;
}
.candidate-dashboard
  .navbar
  .navbar-nav
  .nav-item
  .nav-link:hover
  .role-change-color {
  color: #f7931e;
}

.candidate-dashboard .navbar .navbar-nav .nav-item .dropdown-menu {
  margin-top: -26px;
  margin-right: 0px;
}

.candidate-dashboard .dropdown-menu .dropdown-item i[class*='fa'] {
  font-size: 18px;
  text-align: center;
  line-height: 25px;
  padding-right: 22px;
}
.candidate-dashboard .navbar .navbar-brand {
  margin: 0px !important;
  padding: 0px !important;
}
.candidate-dashboard .sidebar .nav .nav-item a img {
  width: 24px;
  height: 24px;
  margin-right: 21px;
  position: relative;
  left: 3px;
  top: 1px;
}
.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle='collapse']
  ~ div
  > ul
  > li
  > a
  .sidebar-normal.my-profile-class {
  left: 15px;
}
.candidate-dashboard
  .sidebar
  .sidebar-wrapper
  .user
  .info
  [data-toggle='collapse']
  ~ div
  > ul
  > li
  > a
  .sidebar-normal.my-profile-class:hover {
  color: #f7931e !important;
}

@media (min-width: 992px) {
  .sidebar-mini .sidebar:hover .user .info .caret {
    top: -7px;
    position: absolute;
    left: 147px;
  }
  .candidate-dashboard .pricing-ing-margin {
    margin-top: 6px;
    margin-bottom: 8px;
  }
  .sidebar-mini .sidebar:hover .pricing-ing-margin {
    margin-bottom: 6px !important;
  }

  .sidebar-mini .sidebar .pricing-ing-margin {
    margin-top: 6px !important;
    margin-bottom: 14px !important;
  }
  .sidebar-mini .sidebar:hover .pricing-label {
    top: 6px !important;
  }
  .candidate-dashboard .navbar .navbar-nav .dropdown-menu .dropdown-item .fa {
    margin-top: 0;
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    .dropdown-item {
    font-size: 13px;
    color: #888888;
  }
  .candidate-dashboard .navbar-expand-lg {
    padding-right: 0px;
    padding-left: 13px;
    border-bottom: none;
  }
  .candidate-dashboard .navbar-nav .nav-item .nav-link .role-change-color {
    color: #90a4ae;
  }
}
@media (max-width: 991px) {
  .candidate-dashboard .navbar-expand-lg .container-fluid .mt-mb-sm-0 {
    margin-top: 0px;
    position: relative;
    top: 7px;
  }
  .candidate-dashboard
    .navbar-expand-lg
    .container-fluid
    .navbar-collapse.collapse {
    background: #ffffff;
    z-index: 1000;
    border-bottom: 1px solid #d5ddde;
    position: relative;
    top: 6px;
  }
  .candidate-dashboard .navbar .navbar-nav .nav-item .nav-link {
    padding: 0px 15px;
    margin: 0px 3px;
  }
  .candidate-dashboard .navbar .navbar-nav .nav-item .nav-link .d-lg-none {
    font-size: 14px;
    color: #11527e;
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .nav-link
    .d-lg-none:hover {
    color: #ffa534;
  }
  .candidate-dashboard .navbar .navbar-nav .dropdown-toggle:after {
    color: #ffa534;
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    .dropdown-item {
    font-size: 13px;
    padding-left: 44px;
    color: #888888;
    border-bottom: 1px solid #ddd;
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    .dropdown-item:first-child {
    border-top: 1px solid #ddd;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .candidate-dashboard
    .navbar
    .navbar-nav
    .nav-item
    .dropdown-menu
    .dropdown-item.change-pin-mobview {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .candidate-dashboard .navbar .navbar-nav .nav-item .dropdown-menu {
    margin-top: 0px;
  }
  .candidate-dashboard .nav-mobile-menu {
    display: none !important;
  }
  .candidate-dashboard .navbar-collapse ul.navbar-nav {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .candidate-dashboard .collapse .navbar-nav .mb-dropdown-sm {
    margin-bottom: 12px;
  }
  .candidate-dashboard .navbar-nav .nav-item {
    border-radius: 12px;
    border: 1px solid #9a9a9a;
    margin-left: 12px;
    margin-right: 12px;
  }
  .candidate-dashboard .main-panel .navbar.navbar-expand-lg {
    padding-right: 0px;
    padding-left: 0px;
  }
  .candidate-dashboard .main-panel .navbar {
    height: 70px;
    max-height: 70px;
  }
  .candidate-dashboard .navbar .navbar-nav .notification {
    left: 3px;
  }
  .candidate-dashboard .navbar-expand-lg {
    border-bottom: none;
  }
}
.candidate-dashboard .pt-position-rel {
  position: relative;
}
/*header css end*/

/*language-container css starts*/

.candidate-dashboard .language-container {
  background: #35dc8c1a;
  height: 36px;
}
.candidate-dashboard #yt-widget .yt-servicelink {
  font-size: 0 !important;
}
@media (min-width: 992px) {
  .candidate-dashboard .language-container {
    padding-right: 30px;
  }
  .candidate-dashboard .search-candidates {
    display: none;
  }
}
@media (max-width: 575px) {
  .candidate-dashboard .language-container .list-unstyled {
    position: absolute;
    bottom: -46px;
    right: 15px;
  }
}
@media screen and (max-width: 991px) and (min-width: 576px) {
  .candidate-dashboard .language-container .list-unstyled {
    position: absolute;
    bottom: -50px;
    right: 15px;
  }
}
/*language-container css end*/

/* search panel css start*/
@media (max-width: 991px) {
  .candidate-dashboard .web-view-search {
    display: none;
  }
  .candidate-dashboard .search-candidates {
    padding-top: 6px;
  }
  /* .candidate-dashboard .search-candidates span,.candidate-dashboard .search-candidates i.fa{
    color: #f7931e;
  } */
  .candidate-dashboard .search-candidates a.togglebox-top:hover {
    color: #23ccef !important;
  }
  .candidate-dashboard .search-candidates a.togglebox-top {
    color: #f7931e;
  }
  .candidate-dashboard .search-candidates .fa {
    font-size: 17px;
  }
  .candidate-dashboard .search-text-top {
    font-size: 13px;
    position: absolute;
    top: 0px;
    left: 15px;
    text-transform: uppercase;
    background: #23ccef;
    border: 1px solid #23ccef;
    color: #fff;
    padding: 8px 7px 7px;
    width: 108px;
    border-radius: 3px;
  }
  .candidate-dashboard .pl-emp-search {
    padding: 8px 7px 7px 10px;
  }
  .candidate-dashboard .search-text-top:hover {
    border: 1px solid #23ccef;
  }
  .candidate-dashboard .search-candidates a:hover {
    color: #23ccef;
  }
  .candidate-dashboard .search-candidates .fa-search {
    position: absolute;
    left: 90px;
    top: 9px;
  }
  .candidate-dashboard .search-candidates .fa.fa-user-circle {
    position: absolute;
    top: 10px;
  }
}
@media (min-width: 992px) {
  .candidate-dashboard .mobile-view-search {
    display: none;
  }
}
.candidate-dashboard .pr-dis-search-btn {
  padding-left: 0px;
  display: flex;
}
.candidate-dashboard .web-view-search {
  margin-top: 17px;
  padding-left: 15px;
  padding-right: 15px;
}
/* search panel css end*/

/*Breadcrumb css start*/
.candidate-dashboard .breadcrumb .breadcrumb-item {
  font-size: 12px;
}
.candidate-dashboard .pad-bradcrumb-right .breadcrumb {
  margin-bottom: 18px;
}

.candidate-dashboard .breadcrumb {
  padding: 0.5rem 1rem;
}
.candidate-dashboard .breadcrumb-item.active a {
  color: #9a9a9a !important;
}
@media (max-width: 991px) {
  .candidate-dashboard .pad-bradcrumb-right {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  .candidate-dashboard .pad-bradcrumb-right .breadcrumb.breadcrumb-revised {
    margin-top: 0rem !important;
  }
}

/*breadcrumb css end*/

/*footer css start*/

.candidate-dashboard .main-panel footer {
  border-top: 1px solid #e7e7e7;
}
.candidate-dashboard footer {
  background-color: #ffffff;
  width: 100%;
  height: 50px;
}
.candidate-dashboard .main-panel footer .copyright {
  padding-top: 15px;
}
/*footer css end*/
.sidebar-mini .sidebar .sidebar-wrapper .user .info .profile-name {
  color: #f7931e;
}

@media (min-width: 992px) {
  .sidebar-mini .sidebar ul li .zero-opacity {
    display: none;
  }
  .sidebar-mini .sidebar:hover ul li .zero-opacity {
    display: initial;
  }
  .sidebar-mini .sidebar .sidebar-wrapper .nav .nav-link p {
    display: none;
  }

  .sidebar-mini .sidebar:hover .sidebar-wrapper .nav .nav-link p {
    display: initial;
    top: 5px;
  }
  .sidebar-mini
    .sidebar:hover
    .sidebar-wrapper
    > .nav
    li
    > span
    .zero-opacity.slow-motion-item {
    position: relative;
    top: 5px;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i {
    /* margin-top: 20px; sidebar panel issue fix*/
  }
  .sidebar-mini .sidebar:hover .nav .nav-item .nav-link i {
    margin-top: 4px;
  }
  .sidebar-mini .sidebar .nav .nav-item .nav-link i.fa.fa-street-view {
    margin-top: 4px;
  }
  .sidebar-mini .sidebar:hover .hover-link-orange:hover {
    color: #f7931e !important;
  }

  .sidebar-mini
    .sidebar
    .sidebar-wrapper
    .user
    .info
    [data-toggle='collapse']
    ~ div
    > ul
    > li
    > a
    .sidebar-mini {
    display: none;
  }
  .sidebar-mini
    .sidebar
    .sidebar-wrapper
    .user
    .info
    [data-toggle='collapse']
    ~ div
    > ul
    > li
    > a
    .sidebar-normal {
    display: none;
  }
  .sidebar-mini
    .sidebar:hover
    .sidebar-wrapper
    .user
    .info
    [data-toggle='collapse']
    ~ div
    > ul
    > li
    > a
    .sidebar-mini {
    display: initial;
  }
  .sidebar-mini
    .sidebar:hover
    .sidebar-wrapper
    .user
    .info
    [data-toggle='collapse']
    ~ div
    > ul
    > li
    > a
    .sidebar-normal {
    display: initial;
  }
  .sidebar-mini .sidebar .sidebar-wrapper .user .info {
    display: flex;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper .user .info {
    display: block;
  }
  .sidebar-mini .sidebar:hover .user .info a {
    padding-bottom: 0px;
    padding-top: 2px;
  }
  .sidebar-mini .sidebar .sidebar-wrapper .user {
    padding-bottom: 38px;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper .user {
    padding-bottom: 0px;
  }

  .sidebar-mini .sidebar .sidebar-wrapper .user .info .profile-name {
    display: none;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper .user .info .profile-name {
    display: initial;
    color: #f7931e;
  }
  .sidebar-mini .sidebar .sidebar-wrapper .user .info .collapse.show {
    margin-top: -15px;
  }
  .sidebar-mini .sidebar:hover .sidebar-wrapper .user .info .collapse.show {
    margin-top: 0px;
  }
  .sidebar-mini .sidebar .nav .nav-item a img {
    top: 8px !important;
  }
  .sidebar-mini .sidebar:hover .nav .nav-item a img {
    top: 8px !important;
  }
  .sidebar-mini .sidebar .nav.employer-links .fa-line-chart {
    margin-top: 0px !important;
  }
  .sidebar-mini .sidebar .nav.employer-links .fa-address-card-o {
    margin-bottom: 15px;
  }
}
