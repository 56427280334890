.container_loader {
  display: block;
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(216, 216, 216, 0.5);
}

.container_loader .machine {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 30vmin;
}

.container_loader .small-shadow,
.container_loader .medium-shadow,
.container_loader .large-shadow {
  fill: rgba(0, 0, 0, 0.05);
}

.container_loader .small {
  -webkit-animation: counter-rotation 2.5s infinite linear;
  -moz-animation: counter-rotation 2.5s infinite linear;
  -o-animation: counter-rotation 2.5s infinite linear;
  animation: counter-rotation 2.5s infinite linear;
  -webkit-transform-origin: 100.136px 225.345px;
  -ms-transform-origin: 100.136px 225.345px;
  transform-origin: 100.136px 225.345px;
  fill: #11527e;
}

.container_loader .small-shadow {
  -webkit-animation: counter-rotation 2.5s infinite linear;
  -moz-animation: counter-rotation 2.5s infinite linear;
  -o-animation: counter-rotation 2.5s infinite linear;
  animation: counter-rotation 2.5s infinite linear;
  -webkit-transform-origin: 110.136px 235.345px;
  -ms-transform-origin: 110.136px 235.345px;
  transform-origin: 110.136px 235.345px;
}

.container_loader .medium {
  -webkit-animation: rotation 3.75s infinite linear;
  -moz-animation: rotation 3.75s infinite linear;
  -o-animation: rotation 3.75s infinite linear;
  animation: rotation 3.75s infinite linear;
  -webkit-transform-origin: 254.675px 379.447px;
  -ms-transform-origin: 254.675px 379.447px;
  transform-origin: 254.675px 379.447px;
  fill: #3fa9f5;
}

.container_loader .medium-shadow {
  -webkit-animation: rotation 3.75s infinite linear;
  -moz-animation: rotation 3.75s infinite linear;
  -o-animation: rotation 3.75s infinite linear;
  animation: rotation 3.75s infinite linear;
  -webkit-transform-origin: 264.675px 389.447px;
  -ms-transform-origin: 264.675px 389.447px;
  transform-origin: 264.675px 389.447px;
}

.container_loader .large {
  -webkit-animation: counter-rotation 5s infinite linear;
  -moz-animation: counter-rotation 5s infinite linear;
  -o-animation: counter-rotation 5s infinite linear;
  animation: counter-rotation 5s infinite linear;
  -webkit-transform-origin: 461.37px 173.694px;
  -ms-transform-origin: 461.37px 173.694px;
  transform-origin: 461.37px 173.694px;
  fill: #f7931e;
}

.container_loader .large-shadow {
  -webkit-animation: counter-rotation 5s infinite linear;
  -moz-animation: counter-rotation 5s infinite linear;
  -o-animation: counter-rotation 5s infinite linear;
  animation: counter-rotation 5s infinite linear;
  -webkit-transform-origin: 471.37px 183.694px;
  -ms-transform-origin: 471.37px 183.694px;
  transform-origin: 471.37px 183.694px;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes counter-rotation {
  from {
    -webkit-transform: rotate(359deg);
  }
  to {
    -webkit-transform: rotate(0deg);
  }
}
@-moz-keyframes counter-rotation {
  from {
    -moz-transform: rotate(359deg);
  }
  to {
    -moz-transform: rotate(0deg);
  }
}
@-o-keyframes counter-rotation {
  from {
    -o-transform: rotate(359deg);
  }
  to {
    -o-transform: rotate(0deg);
  }
}
@keyframes counter-rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}
