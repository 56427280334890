.c-dashboard .main-panel {
  background: #f4f3ef;
}

.c-dashboard .headertopnavmenus.navbar-nav .nav-item a i {
  -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
}

.c-dashboard .headertopnavmenus.navbar-nav .nav-item a i:hover {
  -webkit-transform: rotate(53deg);
  -ms-transform: rotate(53deg);
  transform: rotate(53deg);
  animation-iteration-count: unset;
  animation-duration: unset;
}

.c-dashboard .headertopnavmenus.navbar-nav .nav-item a .notification {
  font-weight: normal;
  position: relative;
  top: 2px;
  font-size: 14px;
}

.c-dashboard .headertopnavmenus.navbar-nav .nav-item a:hover .notification {
  color: #f8931d;
}

.c-dashboard .viewallnotification {
  border-top: 1px solid #ddd;
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
  color: #24a0ed !important;
  font-weight: bold !important;
}

.c-dashboard .headertopnavmenus.navbar-nav .nav-item.logout {
  display: none;
}

.c-dashboard .no-container_lang_dash {
  margin: 0px 0px;
}

.c-dashboard .main-panel > .content_dash {
  margin-top: 0px !important;
  padding: 0px;
}

.c-dashboard .language_container {
  width: auto;
}

.c-dashboard .language_container_dashboard {
  height: 37px !important;
  padding: 0px 0 !important;
}

.c-dashboard .padding_top_language_container {
  margin-top: 0px !important;
  padding-right: 23px;
}

.c-dashboard #ytWidget .yt-wrapper {
  margin-top: 0px;
}

.navbar .navbar-nav .nav-item .nav-link {
  color: #000;
}

.candidate-dashboard .navbar .navbar-nav .nav-item .nav-link .d-lg-none {
  color: #000 !important;
}

.c-dashboard .dropdown-item,
.c-dashboard .role-change-color {
  color: #000 !important;
}

.c-dashboard .fa-sign-out,
.c-dashboard .sign-out-web,
.signout-mob {
  color: #fb0d64 !important;
}

.btn-post-job,
.btn-post-job:hover {
  background-color: #f7931e;
  color: #fff;
}

.c-dashboard .fa-plus,
.c-dashboard .orange-text {
  color: #f7931e;
  font-size: 12px;
}

.emp-post-job-header {
  color: #fff;
}

.fa-iconthumtack {
  color: #fff;
}

.candidate-profile-completion {
  color: #f7931e;
  display: inline-block !important;
}

@media screen and (max-width: 991px) and (min-width: 576px) {
  .sign-out-mobile {
    display: block;
  }

  .sign-out-web {
    display: none;
  }

  /* .for-web {
    display: none;
  }

  .for-mobile {
    display: block;
  } */
}

.sign-out-mobile {
  display: none;
  margin-bottom: 5px;
}

.sign-out-web {
  display: block;
  font-weight: bold;
}

/* .for-web {
  display: block;
}

.for-mobile {
  display: none;
} */

@media (min-width: 991px) {
  .navbar-nav .covid-impacted .row {
    padding: 31px 18px 0px 15px;
  }

  .navbar-nav .covid-impacted .row i {
    font-size: 20px;
    position: relative;
    bottom: 2px;
    margin-right: 4px;
  }
}

@media (max-width: 991px) {
  .navbar-nav li.covid-impacted {
    display: none !important;
  }
  .navbar-nav .covid-impacted .row {
    padding: 13px 1px 5px;
    margin: 0px 3px;
  }
  .navbar-nav .covid-impacted .row i {
    font-size: 17px;
    position: relative;
    bottom: 1px;
    margin-right: 3px;
  }
  .navbar-expand-lg .covid-impacted label {
    font-size: 12px;
    color: #23ccef;
  }
  .navbar-expand-lg .covid-impacted label i {
    color: #f7931e;
  }
  .navbar-expand-lg .covid-impacted .row i {
    font-size: 17px;
    position: relative;
    bottom: 1px;
    margin-right: 3px;
  }
  .navbar-expand-lg .covid-impacted .row {
    padding: 15px 10px 0px 0px;
  }
  .navbar-expand-lg .covid-impacted .row .d-flex {
    padding: 0px;
  }
  .signout-mob {
    font-weight: bold;
  }
}
.covid-impacted {
  list-style-type: none !important;
}
.navbar-nav .covid-impacted label {
  font-size: 12px;
  color: #23ccef;
}
.navbar-nav .covid-impacted label i {
  color: #f7931e;
}
.navbar .navbar-toggler.navbar-toggler-right.mobile-menu-button {
  height: auto;
  width: auto;
  padding-right: 16px;
}
.title-text {
  font-size: 13;
  text-transform: capitalize;
}
