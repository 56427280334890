.card .card-header {
  border-radius: 10px;
}

@media screen and (max-width: 992px) {
  .revised-hide-mob-menu {
    right: 0 !important;
    left: auto !important;
    transform: translate3d(240px, 0, 0) !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 10000 !important;
  }

  .revised-show-mob-menu {
    right: 0 !important;
    left: auto !important;
    transform: none !important;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    z-index: 10000 !important;
  }
}
.candidate-dashboard .sidebar .nav .nav-item .nav-link i {
  margin-top: 3px;
}
.card .card-title {
  color: #808080;
  font-size: 17px;
}
.card-wizard .nav-tabs {
  margin-top: 10px;
}
.card-stats .card-body .numbers {
  text-align: center;
  padding-top: 25px;
}
.card-stats .card-body .numbers .card-title {
  font-size: 40px;
}
.card.card-stats:hover .numbers .card-title {
  color: #f7931e;
}
.card-wizard .nav-tabs .nav-item {
  width: 50%;
}
.display-select-save .bootstrap-select > .dropdown-toggle {
  width: 100px;
}
.nav-tabs {
  border: none;
}
.card .table thead th:last-child {
  padding-right: 15px;
  display: revert;
}
.card .card-body .control-label {
  color: #2c2c2c;
}
.card-wizard {
  border: none;
  border-radius: 10px;
}
.card-stats {
  border-radius: 10px;
}
.padd-dash-boxes {
  padding-left: 15px;
  padding-right: 15px;
}
.col-5 .icon-big img {
  width: 40px;
  height: 40px;
}
.card .card-footer .stats {
  padding-left: 20px;
}
#tab2 tbody td:last-child .fa {
  padding-top: 1px;
}
@media (max-width: 575px) {
  .padd-ltrt-zero-sm {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card-stats {
    margin-bottom: 20px;
  }
  #tab2 tbody td:last-child {
    display: flex;
    position: relative;
  }
  .padd-dash-boxes .form-group .control-label {
    padding-top: 0px;
  }
}
.table-full-width {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 0px 15px 0px !important;
}

.padding-globe-top {
  padding-top: 7px !important;
}
.sidebar .nav .nav-item .nav-link .new-role-color {
  color: #23ccef !important;
}
.sidebar .sidebar-wrapper .user .info .status-employer {
  cursor: context-menu !important;
}
@media (max-width: 991px) {
  .sidebar .employer-dashboard-navbar.nav .nav-item .nav-link i {
    float: left !important;
  }
}
.sidebar .employer-dashboard-navbar.nav .nav-item .nav-link i {
  float: left;
}
.sidebar-mini .employer-dashboard-navbar.nav .nav-item .nav-link i {
  float: none;
}
.sidebar .employer-dashboard-navbar.nav .nav-item .nav-link .fa-bell,
.sidebar .employer-dashboard-navbar.nav .nav-item .nav-link .fa-industry,
.sidebar .employer-dashboard-navbar.nav .nav-item .nav-link .fa-handshake-o {
  font-size: 15px;
}
.logo .last-logged-label {
  color: #23ccef;
}
