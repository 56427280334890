@media screen and (max-width: 1280px) and (min-width: 770px) {
  .custom-mb-3 {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 769px) {
  .custom-mb-3 {
    margin-bottom: 0rem !important;
  }
}

.searchForm {
  width: inherit;
}
