.admin-dashboard .card .card-header {
  border-radius: 10px;
}
.admin-dashboard .card .card-title {
  color: #808080;
  font-size: 17px;
}
.admin-dashboard .card-wizard .nav-tabs {
  margin-top: 10px;
}
.admin-dashboard .card-stats .card-body .numbers {
  text-align: center;
  padding-top: 25px;
}
.admin-dashboard .card-stats .card-body .numbers .card-title {
  font-size: 40px;
}
.admin-dashboard .card.card-stats:hover .numbers .card-title {
  color: #f7931e;
}
.admin-dashboard .card-wizard .nav-tabs .nav-item {
  width: 50%;
}
.admin-dashboard .display-select-save .bootstrap-select > .dropdown-toggle {
  width: 100px;
}
.admin-dashboard .nav-tabs {
  border: none;
}
.admin-dashboard .card .table thead th:last-child {
  padding-right: 15px;
  display: revert;
}
.admin-dashboard .card .card-body .control-label {
  color: #2c2c2c;
}
.admin-dashboard .card-wizard {
  border: none;
  border-radius: 10px;
}
.admin-dashboard .card-stats {
  border-radius: 10px;
}
.admin-dashboard .padd-dash-boxes {
  padding-left: 15px;
  padding-right: 15px;
}
.admin-dashboard .col-5 .icon-big img {
  width: 40px;
  height: 40px;
}
.admin-dashboard .card .card-footer .stats {
  padding-left: 20px;
}
.admin-dashboard #tab2 tbody td:last-child .fa {
  padding-top: 1px;
}
@media (max-width: 575px) {
  .admin-dashboard .padd-ltrt-zero-sm {
    padding-left: 0px;
    padding-right: 0px;
  }
  .admin-dashboard .card-stats {
    margin-bottom: 20px;
  }
  .admin-dashboard #tab2 tbody td:last-child {
    display: flex;
    position: relative;
  }
  .admin-dashboard .padd-dash-boxes .form-group .control-label {
    padding-top: 0px;
  }
}

.admin-dashboard .table-full-width {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding: 0px 0px 15px 0px !important;
}

.admin-dashboard .tabing a.nav-item.nav-link {
  text-align: center;
  border: none;
  background-color: #9a9a9a;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: unset;
  display: block;
  padding: 0.5rem 1rem;
}

.admin-dashboard .tabing a.nav-item.nav-link.active {
  background-color: #24ccef;
  color: #fff;
}

.admin-dashboard .view-icon {
  color: #23ccef !important;
}

.admin-dashboard .save-icon {
  color: #ffa534 !important;
  margin-left: 5px;
}
.admin-dashboard .bcbMzh {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
}
