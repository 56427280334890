.c-dashboard .institute-label {
  padding: 28px 20px;
  color: #23ccef;
}
@media (max-width: 576px) {
  .institute-title {
    width: 60%;
    background: #fff;
    border: none;
    display: none;
  }
  .dropdown .header-company-dropdown {
    padding: 0 !important;
    margin: 0;
    width: 100%;
    border: none;
  }
  .c-dashboard .institute-label {
    padding: 10px 20px;
  }
}
@media (min-width: 991px) {
  .institute-title {
    width: 42%;
    background: #fff;
    border: none;
  }
  .recruiter-company-dropdown {
    padding-top: 20px;
    margin-right: 18px;
  }
  .dropdown .header-company-dropdown {
    width: 420px;
  }
}
@media (max-width: 991px) {
  .recruiter-company-list {
    border-radius: 6px !important;
  }
  .dropdown .header-company-dropdown {
    margin-bottom: 0px;
  }
}
