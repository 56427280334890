.employer-dashboard .card .card-header {
  border-radius: 10px;
}
.employer-dashboard .card .card-title {
  color: #808080;
  font-size: 17px;
}
.employer-dashboard .card-wizard .nav-tabs {
  margin-top: 10px;
}
.employer-dashboard .card-stats .card-body .numbers {
  text-align: center;
  padding-top: 25px;
}
.employer-dashboard .card-stats .card-body .numbers .card-title {
  font-size: 40px;
}
.employer-dashboard .card.card-stats:hover .numbers .card-title {
  color: #f7931e;
}
.employer-dashboard .card-wizard .nav-tabs .nav-item {
  width: 50%;
}
.employer-dashboard .display-select-save .bootstrap-select > .dropdown-toggle {
  width: 100px;
}
.employer-dashboard .nav-tabs {
  border: none;
}
.employer-dashboard .card .table thead th:last-child {
    padding-right: 15px;
    display: revert;
}
.employer-dashboard .card .card-body .control-label {
	color:#2c2c2c;
}
.employer-dashboard .card-wizard {
    border:none;
    border-radius:10px;	
}
.employer-dashboard .card-stats{
	border-radius:10px;
}
.employer-dashboard .padd-dash-boxes{
	padding-left:15px;
	padding-right:15px;
}
.employer-dashboard .col-5 .icon-big img {
    width: 40px;
    height: 40px;
}
.employer-dashboard .card .card-footer .stats {
	padding-left:20px;
}
.employer-dashboard #tab2 tbody td:last-child .fa{
	padding-top:1px;
}
@media (max-width: 575px){
	.employer-dashboard .padd-ltrt-zero-sm{
		padding-left:0px;
		padding-right:0px;
	}	
	.employer-dashboard .card-stats{
		margin-bottom:20px;
	}
	.employer-dashboard #tab2 tbody td:last-child{
		display: flex;
		position: relative;
	}
	.employer-dashboard .padd-dash-boxes .form-group .control-label{
		padding-top:0px;
	}

}

.employer-dashboard .table-full-width {
	margin-left:0px !important;
	margin-right:0px !important;
	padding: 0px 0px 15px 0px!important;
}

.employer-dashboard .tabing a.nav-item.nav-link{
	text-align: center;
    border: none;
    background-color: #9A9A9A;
    color: #FFFFFF;
    text-transform: uppercase;
	border-radius: unset;
	display: block;
    padding: .5rem 1rem;

}

.employer-dashboard .tabing a.nav-item.nav-link.active {
	background-color: #24ccef;
	color: #fff;
}

.employer-dashboard .view-icon{
	color: #23CCEF !important;
}

.employer-dashboard .save-icon{
	color: #FFA534 !important;
	margin-left: 5px;
}
.employer-dashboard .bcbMzh{
	border: 1px solid rgba(0, 0, 0, .125);
	border-radius: 2px;
}
